import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';
import { NpsService } from '../services/nps.service';
import { AdminAuthService } from '../services/AdminAuth.Service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [
    trigger('fadeOut', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('in => out', [animate('0.5s ease-out')])
    ])
  ]
})
export class LayoutComponent implements OnInit {
  view_header_footer: boolean = false;
  showFiller: boolean = true;
  lang: any;
  showNps: boolean;
  hideBanner = false;
  urlForOldBO: string = '';
  fadeState: string = 'in';
  constructor(
    public translate: TranslateService,
    private cookie: CookieService,
    private npsService: NpsService,
    private adminService: AdminAuthService
  ) { }

  ngOnInit() {
    this.lang = this.cookie.get('lang');
    this.translate.onLangChange.subscribe((event: any) => {
      this.lang = event.lang;
    });

    const user = JSON.parse(localStorage.getItem('User'));

    if (user) {
      this.view_header_footer = true;
      const token = localStorage.getItem('JWT_TOKEN');
      const adminId = localStorage.getItem('adminId');
      if (token) {
        this.urlForOldBO = `https://test-new.partner.nugttah.com/redirect?log=n&accesstoken=${token}&id=${user.id}&image=${user.image_path}&brand=${user.brand}&adminId=${adminId}`;
      } else {
        console.warn('Token is missing from localStorage');
        this.urlForOldBO = '';
      }
    }

    // if (user != null) {
    //   this.view_header_footer = true;
    // }

    const isAgent = localStorage.getItem('isAgent');
    this.adminService.isAuthenticated().then((res: any) => {
      if (res.IsLoggedIn && isAgent !== 'true') {
        this.checkShowNps();
      }
    });

  }

  checkShowNps() {
    this.npsService.checkIfPartnerShouldRate().subscribe(
      (response: any) => {
        if (response.data.businessShouldRate) {
          this.showNps = true;
        }
      }
    );
  }

  openSide() {
    this.showFiller = true;
  }

  closeBanner() {
    this.fadeState = 'out'; // Trigger animation
  }

  onAnimationDone() {
    if (this.fadeState === 'out') {
      this.hideBanner = true; // Remove from DOM
    }
  }

}