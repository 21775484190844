import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { CookieService } from 'ngx-cookie';
import { AdminAuthService } from 'src/app/services/AdminAuth.Service';
import { BranchesService } from 'src/app/services/branches.service';
import { BusinessService } from 'src/app/services/business.Service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }]
})
export class HeaderComponent implements OnInit {
  menu = false;
  userId: any;
  userName: any;
  userEmail: any;
  headerResult: any;
  showLang = false;
  @Output() openSide = new EventEmitter();
  currentLang: any = 'en';
  lang: any;
  showAlert: boolean = true;
  subscriptionBranchesDetails: any;
  branchNames: { en: string; ar: string }[] = [];
  subscriptionEndDate = [];
  constructor(
    private router: Router,
    private authService: AdminAuthService,
    private businessService: BusinessService,
    public translate: TranslateService,
    private cookieService: CookieService,
    private branchesService: BranchesService
  ) {
    if (this.cookieService.get('lang') == null || this.cookieService.get('lang') === 'en') {
      this.changeLanguage('en');
    } else {
      this.changeLanguage('ar');
    }
  }

  openMenu() {
    this.menu = !this.menu;
    this.openSide.emit();
  }

  profile_image_path = 'assets/avatar.jpg';

  ngOnInit() {
    this.lang = this.cookieService.get('lang');
    this.translate.onLangChange.subscribe((e: any) => {
      this.lang = e.lang;
    });
    const user = JSON.parse(localStorage.getItem('User'));
    this.profile_image_path = user.image_path || 'assets/avatar.jpg';

    this.userId = user.id;
    this.businessService.GetOne(this.userId, '').subscribe(res => {
      this.userName = `${res.body.data.firstname} ${res.body.data.lastname}`;
      this.userEmail = res.body.data.email;


    });

    this.getSubscriptionBranches();

  }

  getSubscriptionBranches() {
    this.branchesService.branchSubscriptionView().subscribe((res: any) => {
      this.subscriptionBranchesDetails = res?.data;
      if (this.subscriptionBranchesDetails?.branches?.length > 0) {
        this.branchNames = [];
        this.subscriptionEndDate = [];
        this.subscriptionBranchesDetails.branches.forEach((branch: any) => {
          this.branchNames.push(branch.name);
          this.subscriptionEndDate.push(branch.subscriptionDetails.subscriptionEndDate);
        });
      }

      if (this.subscriptionBranchesDetails?.count > 0) {
        const bread = document.querySelector('.bread');
        if (bread) {
          bread.classList.add('height-150');
        }
      }
    });
  }

  closeAlert() {
    this.showAlert = false;
    // remove height-150 class
    const bread = document.querySelector('.bread');
    if (bread) {
      bread.classList.remove('height-150');
    }
  }
  logout() {
    this.authService.LogOut().subscribe(config => {
      window.location.reload();
      return this.router.navigate(['/']);
    });
  }



  changeLanguage(lang: any) {
    setTimeout(() => {
      this.cookieService.put('lang', lang);
      localStorage.setItem('lang', lang);
      this.translate.use(lang);
      this.currentLang = lang;
      const html: any = document.getElementsByTagName('html')[0];
      if (html) {
        const langDir = lang === 'ar' ? 'rtl' : 'ltr';
        html.dir = langDir;
        html.classList.remove('rtl', 'ltr');
        html.classList.add(langDir);
      }
    }, 500);
  }
}